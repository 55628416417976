.logo {
    margin: 14px 23px;
    text-wrap: nowrap;
}

.container {
    width: 100vw; 
    min-height: 40vh; 
    background-color: #3C372F;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    align-content: flex-end;
    flex-wrap: wrap;

}

.link {
    margin: 0.5rem 0rem !important;
}

.link:hover {
    color: #ffffff !important;
    filter: drop-shadow(-2px 4px 8px rgba(0, 0, 0, 0.20));
}

@media (max-width: 500px) {  /* smaller desktops */ 
    .col1 {
      margin: 3rem 0 2rem 0;
      width: 80vw;
    }
}
  

.navbar-button {
    margin: 6px 15px;
    align-self: center;
    text-wrap: nowrap;
}

.fade-in {
    animation: fadein 3s ease-in-out;
}

@keyframes fadein {
    0% { opacity: 0 }
    20% { opacity: 0 }
    100%  { opacity: 1 }
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
  }