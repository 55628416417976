:root {
  --banner-opacity: 0.75;
}


.App {
  text-decoration: inherit;
  text-decoration-line: none;
}

.bannercontainer {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  background-color: #000000;
  z-index: -1;
}

.banner {
  position: relative;
  padding-top: 20%;
  width: 100%;
  height: 50vh;
  top: 0;
  z-index: 0;
  background-image: linear-gradient(rgba(0, 0, 0, var(--banner-opacity)), rgba(0, 0, 0, var(--banner-opacity))), url("./assets/drums.png");
  background-size: cover;
  background-position: 50%;
}  

.about {
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 300px;
  /* max-width: 100vw; */
  align-content: center;
  padding: 10% 0%;
}

.services {
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  padding: 4rem 10vw;
}

.testimonials {
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  padding: 4rem 10vw;
}

.servicecard {
  background-color: #CDC8BC;
  border-radius: 11px;
  border: 1px solid #3C372F;
  width: 15vw;
  min-width: 320px;
  max-width: 450px;
  filter: drop-shadow(-2px 4px 8px rgba(0, 0, 0, 0.10));
  margin: 0.5rem;
  margin-bottom: 1rem;
}

.testimonialcard {
  background-color: #D9D9D9;
  border-radius: 11px;
  width: 35vw;
  min-width: 350px;
  max-width: 570px;
  filter: drop-shadow(-2px 4px 8px rgba(0, 0, 0, 0.10));
  margin: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.profile {
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: 0% 20%;
  filter: drop-shadow(-3px 3px 15px rgba(0, 0, 0, 0.25));
  border-radius: 7px;
}


@media (min-width: 1270px) {  /* larger desktops */ 
  .services {
    justify-content: space-between;
  }
  .servicecard {
    margin: 0px !important;
  }

  .testimonialcard {
    margin: 0px;
  }
}

@media (max-width: 850px) {  /* mobile desktops */ 
  .servicecard {
    margin: 1em;
    margin-bottom: 1rem;
  }

  .testimonials {
    justify-content: center;
  }

  .testimonialcard {
    min-width: 350px;
  }

  .about {
    max-height: none;
  }

  .profile {
    width: 90vw;
    height: 90vw;
    max-width: 350px;
    max-height: 350px;
  }
}


@media (max-width: 500px) {  /* smaller desktops */ 
  .testimonialcard {
    min-width: 80vw;
    margin: 0.5rem 0;
  }

  .servicecard {
    min-width: calc(80vw - 2px); /* calculate border size */
    margin: 0.5rem 0;
  }

  .profile {
    margin-bottom: 0.75em;
  }
}

a {
  text-decoration: inherit;
  text-decoration-line: none;
  color: #FFFFEEEE;
  font-weight: 500;
}

a:hover {
  color: #ad952dee;

}

.nav-wrapper {
  padding-left: 0;
  margin-top: 6rem;
  max-width: 250px;
}

.nav-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1.5rem;
}

ul {
  list-style: none;
}

.active .nav-indicator {
  width: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240/var(--tw-bg-opacity));
}

.nav-indicator:hover {
  width: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(126 232 240/var(--tw-bg-opacity));
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105/var(--tw-bg-opacity));
}

.w-8 {
  width: 2rem;
}

.h-px {
  height: 1px;
}
.mr-4 {
  margin-right: 1rem;
}

.active .nav-text {
  --tw-text-opacity: 1;
  color: #C7D1C8; /*rgb(226 232 240/var(--tw-text-opacity));*/
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color:  #9AAC9C; /*rgb(100 116 139/var(--tw-text-opacity));*/
}

.tracking-widest {
  letter-spacing: .1em;
}

.uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: 700;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.icons-link-wrapper {
  margin-top: 20px;
}